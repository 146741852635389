// export
import { observer } from "mobx-react-lite";
import { motion } from "framer-motion";
import Drawer from "@mui/material/Drawer";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import ReviewsIcon from "@mui/icons-material/Reviews";
import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Chip from "@mui/material/Chip";
import { v4 as uuidv4 } from "uuid";
// internal
import "../css/feedDrawer.scss";
import { HandleFeedCardClose } from "./Helper";

const ObserveRedditFeedDrawer = observer(({ user }) => {
  return (
    <Drawer
      sx={{
        width: user.redditFeedDrawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: user.redditFeedDrawerWidth,
        },
        backgroundColor: "#f3f5f7",
      }}
      variant="persistent"
      anchor="right"
      open={user.redditFeedDrawer}
    >
      <div className="fd-container">
        <div className="fd-topbar-container">
          <motion.div
            className="menu-container"
            onClick={() => {
              HandleFeedCardClose(user, "reddit");
            }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.8 }}
          >
            <div className="icon-container">
              <NavigateBeforeRoundedIcon sx={{ fontSize: "40px" }} />
            </div>
          </motion.div>
        </div>

        <div className="fd-content-container">
          {user.selectedRedditPost ? (
            <div className="fd-content-card">
              <div className="fd-content-card-item">
                <div className="fd-content-card-icon">
                  <ChatBubbleOutlineRoundedIcon sx={{ fontSize: "22px" }} />
                </div>
                <span className="fd-content-card-text">
                  {user.selectedRedditPost.title}
                </span>
              </div>
              <div className="fd-content-card-item">
                <div className="fd-content-card-icon">
                  <ChatBubbleOutlineRoundedIcon sx={{ fontSize: "22px" }} />
                </div>
                <span className="fd-content-card-text">
                  {user.selectedRedditPost.text}
                </span>
              </div>
              {user.selectedRedditPost.tags ? (
                <div className="fd-content-card-item">
                  <div className="fd-content-card-icon">
                    <ReviewsIcon sx={{ fontSize: "22px" }} />
                  </div>
                  <div className="fd-content-card-tags">
                    {user.selectedRedditPost.tags.map((tag) => (
                      <Chip
                        className="mc-card-topics-chip"
                        label={tag}
                        variant="outlined"
                        key={uuidv4()}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="fd-content-card-item">
                <div className="fd-content-card-icon">
                  <EventIcon sx={{ fontSize: "22px" }} />
                </div>
                <span className="fd-content-card-text">
                  {new Date(user.selectedRedditPost.date).toLocaleString()}
                </span>
              </div>
              <div className="fd-content-card-item">
                <div className="fd-content-card-icon">
                  <LocationOnIcon sx={{ fontSize: "22px" }} />
                </div>
                <span className="fd-content-card-text">
                  {user.selectedRedditPost.source}
                </span>
              </div>
              <div
                className="fd-content-card-item"
                onClick={() => {
                  window.open(user.selectedRedditPost.url, "_blank");
                }}
              >
                <span className="fd-content-card-link">Link to source</span>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Drawer>
  );
});

const RedditFeedDrawer = ({ user }) => {
  return <ObserveRedditFeedDrawer user={user} />;
};

export default RedditFeedDrawer;

// lodash
import _ from "lodash";
// internal
import { GetProfile } from "../api/core";

// actionability filter
import { Configuration, OpenAIApi } from "openai";

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export async function HandleFeedCardClick(user, type) {
  if (type === "twitter") {
    await user.setRedditFeedDrawer(false);
    await user.setRedditFeedDrawerWidth("0%");
    await user.setAppStoreFeedDrawer(false);
    await user.setAppStoreFeedDrawerWidth("0%");
    if (user.twitterFeedDrawer === false) {
      await user.setTwitterFeedDrawerWidth("40%");
      await user.setTwitterFeedDrawer(true);
    }
  } else if (type === "reddit") {
    await user.setTwitterFeedDrawer(false);
    await user.setTwitterFeedDrawerWidth("0%");
    await user.setAppStoreFeedDrawer(false);
    await user.setAppStoreFeedDrawerWidth("0%");
    if (user.redditFeedDrawer === false) {
      await user.setRedditFeedDrawerWidth("40%");
      await user.setRedditFeedDrawer(true);
    }
  } else if (type === "app_store") {
    await user.setTwitterFeedDrawer(false);
    await user.setTwitterFeedDrawerWidth("0%");
    await user.setRedditFeedDrawer(false);
    await user.setRedditFeedDrawerWidth("0%");
    if (user.appStoreFeedDrawer === false) {
      await user.setAppStoreFeedDrawerWidth("40%");
      await user.setAppStoreFeedDrawer(true);
    }
  }
  let elem = document.getElementById("main-content-container");
  elem.style.width = "calc(100% - 40%)";
}

export async function HandleFeedCardClose(user, type) {
  if (type === "twitter") {
    await user.setTwitterFeedDrawer(false);
    await user.setTwitterFeedDrawerWidth("0%");
  } else if (type === "reddit") {
    await user.setRedditFeedDrawer(false);
    await user.setRedditFeedDrawerWidth("0%");
  } else if (type === "app_store") {
    await user.setAppStoreFeedDrawer(false);
    await user.setAppStoreFeedDrawerWidth("0%");
  }
  let elem = document.getElementById("main-content-container");
  elem.style.width = "100%";
}

async function constructFeed(user, results) {
  let tweets = results.profile.tweets;
  let appStoreReviews = results.profile.app_store_reviews;
  let redditPosts = results.profile.reddit_posts;
  let newFeed = [];
  for (let t in tweets) {
    if (tweets[t].label !== "neither" && user.twitterFilter) {
      newFeed.push(tweets[t]);
    }
  }
  for (let a in appStoreReviews) {
    if (appStoreReviews[a].label !== "neither" && user.appStoreFilter) {
      newFeed.push(appStoreReviews[a]);
    }
  }
  for (let r in redditPosts) {
    if (redditPosts[r].label !== "neither" && user.redditFilter) {
      newFeed.push(redditPosts[r]);
    }
  }
  await user.setFeed(_.orderBy(newFeed, ["date"], ["desc"]));
}

async function constructInsights(user, results) {
  let labels = [];
  let data = [];

  // // Trying out filtering based on average volume to get middle results - in theory being the more "actionable ones
  // // Do filtering based on Average Tag Volume
  // let tagSum = 0;
  // let tagCount = 0;
  // let tagAvg = 0;

  // for (let t in results.profile.tags) {
  //   tagSum += results.profile.tags[t][1];
  //   tagCount++;
  // }

  // tagAvg = Math.floor(tagSum / tagCount);

  // Do filtering based on ChatGPT actionability rating
  // TODO: Replace w VoC's OpenAI apiKey in an env variable 
  const configuration = new Configuration({
    apiKey: "",
  });
  const openai = new OpenAIApi(configuration);

  if (results.profile.tags) {
    for (let t in results.profile.tags) {
      if (labels.length > 9) {
        break;
      }

      // Trying out filtering based on average volume to get middle results - in theory being the more "actionable ones"
      // if (results.profile.tags[t][1] > tagAvg) {
      //   continue;
      // }

      const completion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [{ "role": "system", "content": "You are a product manager at a startup." }, { role: "user", content: "Using only a single number in your response between 0-10, how clearly actionable is this feedback? \n\n Feedback: " + results.profile.tags[t][0] }],
      });

      // console.log(parseInt(completion.data.choices[0].message.content.slice(-1)));

      // Sometimes API returns w non-Integer response, in this case skip over to the next one.
      // TODO: Better error catching on why non-Integers happen
      if (Number.isNaN(parseInt(completion.data.choices[0].message.content.slice(-1)))) {
        continue;
      }

      // Only push tags that are at least an 8 on a scale of 1-10 actionability, determined by chatGPT
      const actionabilityThresh = 7;
      if (parseInt(completion.data.choices[0].message.content.slice(-1)) > actionabilityThresh) {
        labels.push(results.profile.tags[t][0]);
        data.push(results.profile.tags[t][1]);
      }
    }
    await user.setTagsLabels(labels);
    await user.setTagsData(data);
  }
}

async function constructTwitterInsights(user, results) {
  let labels = [];
  let data = [];
  if (results.profile.twitter_tags) {
    for (let t in results.profile.twitter_tags) {
      if (labels.length > 9) {
        break;
      }
      labels.push(results.profile.twitter_tags[t][0]);
      data.push(results.profile.twitter_tags[t][1]);
    }
    await user.setTwitterTagsLabels(labels);
    await user.setTwitterTagsData(data);
  }
}

async function constructRedditInsights(user, results) {
  let labels = [];
  let data = [];
  if (results.profile.subreddit_tags) {
    for (let t in results.profile.subreddit_tags) {
      if (labels.length > 9) {
        break;
      }
      labels.push(results.profile.subreddit_tags[t][0]);
      data.push(results.profile.subreddit_tags[t][1]);
    }
    await user.setSubredditTagsLabels(labels);
    await user.setSubredditTagsData(data);
  }
}

async function constructAppStoreInsights(user, results) {
  let labels = [];
  let data = [];
  if (results.profile.app_store_tags) {
    for (let t in results.profile.app_store_tags) {
      if (labels.length > 9) {
        break;
      }
      labels.push(results.profile.app_store_tags[t][0]);
      data.push(results.profile.app_store_tags[t][1]);
    }
    await user.setAppStoreTagsLabels(labels);
    await user.setAppStoreTagsData(data);
  }
}

async function orderedFeedPolling(user) {
  await user.setGenerationComplete(false);
  while (user.generationComplete === false) {
    await sleep(1000);
    let results = await GetProfile(user);
    console.log(results);
    constructFeed(user, results);
    constructInsights(user, results);
    constructTwitterInsights(user, results);
    constructRedditInsights(user, results);
    constructAppStoreInsights(user, results);
    await sleep(10000);
    if (results.profile.tags) {
      await user.setGenerationComplete(true);
    }
  }
}

export function FeedPolling(user) {
  orderedFeedPolling(user);
}

async function orderedApplyFilter(user) {
  let results = await GetProfile(user);
  console.log(results);
  constructFeed(user, results);
}

export function ApplyFilter(user) {
  orderedApplyFilter(user);
}

// export
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bar, Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

// internal
import LeftBar from "./LeftBar";
import FakeFilter from "./FakeFilter";
import "../css/insights.scss";
import { FeedPolling } from "./Helper";

Chart.register(...registerables);

const allSourcesChartOptions = {
    indexAxis: "y",
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: "right",
        },
        title: {
            text: "Most Actionable Feedback",
            display: true,
        },
    },
};

const twitterChartOptions = {
    indexAxis: "y",
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: "right",
        },
        title: {
            text: "Twitter",
            display: true,
        },
    },
};

const redditChartOptions = {
    indexAxis: "y",
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: "right",
        },
        title: {
            text: "Reddit",
            display: true,
        },
    },
};

const appStoreChartOptions = {
    indexAxis: "y",
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: "right",
        },
        title: {
            text: "Apple App Store",
            display: true,
        },
    },
};

const ObserveInsights = observer(({ user, navigate }) => {

    // // Played around with adding some filter buttons for diagnosis... 
    // const [filterLabels, setFilterLabels] = useState(user.tagsLabels);
    // const [filterData, setFilterData] = useState(user.tagsData);

    // const handleClick = (value) => {
    //     if (value == 'all') {
    //         setFilterLabels(user.tagsLabels);
    //         setFilterData(user.tagsData);
    //     } else if (value === 'twitter') {
    //         setFilterLabels(user.twitterTagsLabels);
    //         setFilterData(user.twitterTagsData);
    //     } else if (value === 'apple') {
    //         setFilterLabels(user.appStoreTagsLabels);
    //         setFilterData(user.appStoreTagsData);
    //     } else if (value === 'reddit') {
    //         setFilterLabels(user.subredditTagsLabels);
    //         setFilterData(user.subredditTagsData);
    //     }
    // };

    return (
        <div className="i-container">
            <LeftBar user={user} navigate={navigate} />
            <div className="i-content-container">
                <div className="mc-header-container">
                    <span className="mc-header">Insights</span>
                </div>
                <FakeFilter />

                {/* Played around with adding filter buttons by source for diagnosis
                    
                <div className="i-filter-card">
                    <div className="i-filter-card-row">
                        <div className="i-filter-group">
                            <div className="i-filter-input-selector filter-left">

                                <button onClick={() => handleClick('all')}>
                                    <span>All Tags</span>
                                </button>
                                <button onClick={() => handleClick('twitter')}>
                                    <span>Twitter</span>
                                </button>
                                <button onClick={() => handleClick('apple')}>
                                    <span>App Store Reviews</span>
                                </button>
                                <button onClick={() => handleClick('reddit')}>
                                    <span>Reddit Posts</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}

                {user.tagsLabels.length > 0 && user.tagsData.length > 0 ? (
                    <>
                        <div className="i-feedback-container-large">
                            <Bar
                                data={{
                                    labels: user.tagsLabels,
                                    datasets: [
                                        {
                                            backgroundColor:
                                                "rgb(255, 99, 132)",
                                            borderColor: "rgb(255, 99, 132)",
                                            data: user.tagsData,
                                        },
                                    ],
                                }}
                                options={allSourcesChartOptions}
                            />
                        </div>
                        <div className="i-feedback-column-3">
                            <div className="i-feedback-column-chart">
                                {user.twitterTagsLabels.length > 0 &&
                                    user.twitterTagsData.length > 0 ? (
                                    <div className="i-feedback-container">
                                        <Doughnut
                                            data={{
                                                labels: user.twitterTagsLabels,
                                                datasets: [
                                                    {
                                                        backgroundColor: [
                                                            "rgba(255, 99, 132, 0.2)",
                                                            "rgba(54, 162, 235, 0.2)",
                                                            "rgba(255, 206, 86, 0.2)",
                                                            "rgba(75, 192, 192, 0.2)",
                                                            "rgba(153, 102, 255, 0.2)",
                                                            "rgba(255, 159, 64, 0.2)",
                                                        ],
                                                        data: user.twitterTagsData,
                                                    },
                                                ],
                                            }}
                                            options={twitterChartOptions}
                                        />
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div className="i-feedback-column-chart">
                                {user.appStoreTagsLabels.length > 0 &&
                                    user.appStoreTagsData.length > 0 ? (
                                    <div className="i-feedback-container">
                                        <Doughnut
                                            data={{
                                                labels: user.appStoreTagsLabels,
                                                datasets: [
                                                    {
                                                        backgroundColor: [
                                                            "rgba(255, 99, 132, 0.2)",
                                                            "rgba(54, 162, 235, 0.2)",
                                                            "rgba(255, 206, 86, 0.2)",
                                                            "rgba(75, 192, 192, 0.2)",
                                                            "rgba(153, 102, 255, 0.2)",
                                                            "rgba(255, 159, 64, 0.2)",
                                                        ],
                                                        data: user.appStoreTagsData,
                                                    },
                                                ],
                                            }}
                                            options={appStoreChartOptions}
                                        />
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div className="i-feedback-column-chart">
                                {user.subredditTagsLabels.length > 0 &&
                                    user.subredditTagsData.length > 0 ? (
                                    <div className="i-feedback-container">
                                        <Doughnut
                                            data={{
                                                labels: user.subredditTagsLabels,
                                                datasets: [
                                                    {
                                                        backgroundColor: [
                                                            "rgba(255, 99, 132, 0.2)",
                                                            "rgba(54, 162, 235, 0.2)",
                                                            "rgba(255, 206, 86, 0.2)",
                                                            "rgba(75, 192, 192, 0.2)",
                                                            "rgba(153, 102, 255, 0.2)",
                                                            "rgba(255, 159, 64, 0.2)",
                                                        ],
                                                        data: user.subredditTagsData,
                                                    },
                                                ],
                                            }}
                                            options={redditChartOptions}
                                        />
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="i-feedback-generating">
                        {sessionStorage.getItem("profile") ? (
                            <div className="i-container-container">
                                <span className="i-feedback-generating-text">
                                    Generating...can take up to 10 minutes
                                </span>
                            </div>
                        ) : (
                            <span
                                className="mc-feedback-create-profile-text"
                                onClick={() => {
                                    navigate("/");
                                }}
                            >
                                Create a profile to begin
                            </span>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
});

const Insights = ({ user }) => {
    const navigate = useNavigate();

    useEffect(() => {
        FeedPolling(user);
    }, []);

    return <ObserveInsights user={user} navigate={navigate} />;
};

export default Insights;

// export
import { observer } from "mobx-react-lite";
import { HandleFeedCardClick } from "./Helper";
import AppleIcon from "@mui/icons-material/Apple";
import Chip from "@mui/material/Chip";
import { v4 as uuidv4 } from "uuid";
import { motion } from "framer-motion";
// internal
import "../css/feedCard.scss";

async function handleCardClick(user, item) {
  await user.setSelectedAppStorePost(item);
  HandleFeedCardClick(user, "app_store");
}

function formatDate(date) {
  let monthArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let theDate = new Date(date);
  let day = theDate.getDate();
  let month = theDate.getMonth() + 1;
  let year = theDate.getFullYear();
  return `${monthArray[month - 1]} ${day} ${year}`;
}

const ObserveAppStoreFeedCard = observer(({ user, item }) => {
  return (
    <motion.div
      className="mc-feedback-card"
      onClick={() => {
        handleCardClick(user, item);
      }}
      key={uuidv4()}
      whileHover={{ scale: 1.03 }}
      whileTap={{ scale: 0.8 }}
    >
      <div className="mc-card-user-container">
        <div className="mc-card-user-left">
          <div className="mc-card-user-icon">
            <AppleIcon sx={{ fontSize: "14px" }} />
          </div>
          <span className="mc-card-user-username" onClick={() => {}}>
            {item.userName}
          </span>
        </div>
        <div className="mc-card-user-right">
          <span className="mc-card-user-date">{formatDate(item.date)}</span>
        </div>
      </div>
      <div className="mc-card-message-container">
        <span className="mc-card-message-title">{item.title}</span>
      </div>
      <div className="mc-card-message-container">
        {item.text.length > 250 ? (
          <span className="mc-card-message-text">
            {item.text.substring(0, 250)}...
          </span>
        ) : (
          <span className="mc-card-message-text">{item.text}</span>
        )}
      </div>
      <div className="mc-card-topics-container">
        {item.tags.map((tag) => (
          <Chip
            className="mc-card-topics-chip"
            label={tag}
            variant="outlined"
            key={uuidv4()}
          />
        ))}
      </div>
    </motion.div>
  );
});

const AppStoreFeedCard = ({ user, item }) => {
  return <ObserveAppStoreFeedCard user={user} item={item} />;
};

export default AppStoreFeedCard;

// external
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { motion } from "framer-motion";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
// internal
import "../css/setProfileCustom.scss";
import { primary } from "../css/muiThemes";

const ObserveSetProfileCustom = observer(({ user }) => {
    return (
        <div className="spc-parent-container">
            <div className="spc-input-container">
                <span className="input-label">Set profile (advanced)</span>
                <div className="spc-input-button-container">
                    <TextareaAutosize
                        className="spc-profile-input"
                        value={user.profile}
                        minRows={1}
                        onChange={(event) => {
                            user.setProfile(event.target.value);
                        }}
                        placeholder="enter a uuid"
                    />
                    <motion.div
                        className="spc-button-container"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.8 }}
                    >
                        <Button
                            variant="contained"
                            theme={primary}
                            style={{ borderRadius: "30px", width: "90%" }}
                            onClick={() => {
                                sessionStorage.setItem("profile", user.profile);
                                window.location.reload(false);
                            }}
                        >
                            <span className="white-text">Submit</span>
                        </Button>
                    </motion.div>
                </div>
            </div>
        </div>
    );
});

const SetProfileCustom = ({ user }) => {
    useEffect(() => {
        user.setProfile(sessionStorage.getItem("profile"));
    }, []);
    return <ObserveSetProfileCustom user={user} />;
};

export default SetProfileCustom;

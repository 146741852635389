// external
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// internal
import LeftBar from "./LeftBar";
import IdeaForm from "./IdeaForm";
import "../css/mvpHome.scss";
import { ShowErrorSnackbar } from "./Snackbars";

const MVPHome = ({ user }) => {
    const navigate = useNavigate();

    useEffect(() => {}, []);

    return (
        <div className="feed-container">
            <LeftBar user={user} navigate={navigate} />
            <IdeaForm user={user} navigate={navigate} />
            <ShowErrorSnackbar user={user} />
        </div>
    );
};

export default MVPHome;

import axios from "axios";

const { REACT_APP_CORE_API_URL } = process.env;

async function createArraysForCreateProfile(user) {
  let subredditsArray, twittersArray, appStoresArray;
  // reddit
  if (user.subreddits.length > 0) {
    subredditsArray = user.subreddits.split(",");
    for (let s in subredditsArray) {
      subredditsArray[s] = subredditsArray[s].replace(/\s/g, "");
    }
  } else {
    subredditsArray = [];
  }
  // twitter
  if (user.twitters.length > 0) {
    twittersArray = user.twitters.split(",");
    for (let t in twittersArray) {
      twittersArray[t] = twittersArray[t].replace(/\s/g, "");
    }
  } else {
    twittersArray = [];
  }
  // apple app store
  if (user.appStores.length > 0) {
    appStoresArray = user.appStores.split(",");
    for (let a in appStoresArray) {
      appStoresArray[a] = appStoresArray[a].replace(/\s/g, "");
    }
  } else {
    appStoresArray = [];
  }
  return [subredditsArray, twittersArray, appStoresArray];
}

export async function CreateProfile(user) {
  try {
    let [subredditsArray, twittersArray, appStoresArray] =
      await createArraysForCreateProfile(user);
    let results = await axios({
      method: "post",
      url: `${REACT_APP_CORE_API_URL}/create_profile/`,
      data: {
        profile_name: user.profileName,
        subreddits: subredditsArray,
        twitters: twittersArray,
        app_stores: appStoresArray,
      },
    });
    return results.data;
  } catch (e) {
    console.log(e);
    user.setErrorSnackbar(true);
    return null;
  }
}

export async function GetProfile(user) {
  try {
    let sessionProfile = sessionStorage.getItem("profile");
    if (user.profileName.length < 1) {
      await user.setProfileName(sessionProfile);
    }
    let results = await axios({
      method: "get",
      url: `${REACT_APP_CORE_API_URL}/profile/${user.profileName}`,
    });
    return results.data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

// export
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
// internal
import "../css/feed.scss";
import LeftBar from "./LeftBar";
import TwitterFeedDrawer from "./TwitterFeedDrawer";
import TwitterFeedCard from "./TwitterFeedCard";
import RedditFeedCard from "./RedditFeedCard";
import RedditFeedDrawer from "./RedditFeedDrawer";
import AppStoreFeedCard from "./AppStoreFeedCard";
import AppStoreFeedDrawer from "./AppStoreFeedDrawer";
import { FeedPolling, ApplyFilter } from "./Helper";

function identifySource(user, item) {
  if (item.source === "twitter") {
    return <TwitterFeedCard user={user} item={item} />;
  }
  if (item.source === "reddit") {
    return <RedditFeedCard user={user} item={item} />;
  }
  if (item.source === "app_store") {
    return <AppStoreFeedCard user={user} item={item} />;
  }
}

async function performFilter(user, target) {
  if (target === "twitter") {
    await user.setTwitterFilter(!user.twitterFilter);
  } else if (target === "reddit") {
    await user.setRedditFilter(!user.redditFilter);
  } else if (target === "app_store") {
    await user.setAppStoreFilter(!user.appStoreFilter);
  }
  await ApplyFilter(user);
}

const ObserveFeed = observer(({ user, navigate }) => {
  return (
    <div className="feed-container">
      <LeftBar user={user} navigate={navigate} />
      <div className="main-content-container" id="main-content-container">
        <div className="mc-header-container">
          <span className="mc-header">Your feed ({user.feed.length})</span>
        </div>
        <div className="mc-filter-container">
          <div className="mc-filter-icon mc-filter-margin">
            <FilterAltOutlinedIcon />
          </div>
          <motion.div
            className={
              user.twitterFilter
                ? "mc-filter-item-selected mc-filter-margin"
                : "mc-filter-item mc-filter-margin"
            }
            whileHover={{ scale: 1.04 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => {
              performFilter(user, "twitter");
            }}
          >
            <span className="mc-filter-item-text">Twitter</span>
          </motion.div>
          <motion.div
            className={
              user.redditFilter
                ? "mc-filter-item-selected mc-filter-margin"
                : "mc-filter-item mc-filter-margin"
            }
            whileHover={{ scale: 1.04 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => {
              performFilter(user, "reddit");
            }}
          >
            <span className="mc-filter-item-text">Reddit</span>
          </motion.div>
          <motion.div
            className={
              user.appStoreFilter
                ? "mc-filter-item-selected mc-filter-margin"
                : "mc-filter-item mc-filter-margin"
            }
            whileHover={{ scale: 1.04 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => {
              performFilter(user, "app_store");
            }}
          >
            <span className="mc-filter-item-text">Apple App Store</span>
          </motion.div>
        </div>
        <div className="mc-feedback-container">
          {user.feed.length > 0 ? (
            <> {user.feed.map((item) => identifySource(user, item))}</>
          ) : (
            <div className="mc-feedback-generating">
              {sessionStorage.getItem("profile") ? (
                <span className="mc-feedback-generating-text">
                  Generating...can take up to 2 minutes
                </span>
              ) : (
                <span
                  className="mc-feedback-create-profile-text"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Create a profile to begin
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      <TwitterFeedDrawer user={user} />
      <RedditFeedDrawer user={user} />
      <AppStoreFeedDrawer user={user} />
    </div>
  );
});

const Feed = ({ user }) => {
  const navigate = useNavigate();

  useEffect(() => {
    FeedPolling(user);
  }, []);

  return <ObserveFeed user={user} navigate={navigate} />;
};

export default Feed;

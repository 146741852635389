// internal
import IntegrationCard from "./IntegrationCard";
import BlankCard from "./BlankCard";
import "../css/integrations.scss";
import SLACK_LOGO from "../assets/slack.svg";
import ZENDESK_LOGO from "../assets/zendesk.svg";
import AMPLITUDE_LOGO from "../assets/amplitude.svg";
import SALESFORCE_LOGO from "../assets/salesforce.svg";
import SURVEYMONKEY_LOGO from "../assets/surveymonkey.svg";
import GONG_LOGO from "../assets/gong.svg";
import INTERCOM_LOGO from "../assets/intercom.svg";
import G2_LOGO from "../assets/g2.svg";
import EMAIL_LOGO from "../assets/email.svg";
import CSV_LOGO from "../assets/csv.svg";

const Integrations = ({ user }) => {
    return (
        <div className="integrations-parent-container">
            {/* <div className="mc-header-container">
                <span className="mc-header">Integrations</span>
            </div> */}
            <div className="integrations-container">
                <div className="integrations-container-row">
                    <IntegrationCard
                        user={user}
                        name="Slack"
                        logo={SLACK_LOGO}
                        description="Platform for team communication"
                    />
                    <IntegrationCard
                        user={user}
                        name="Zendesk"
                        logo={ZENDESK_LOGO}
                        description="Web-based help desk support tool"
                    />
                    <IntegrationCard
                        user={user}
                        name="Amplitude"
                        logo={AMPLITUDE_LOGO}
                        description="Product analytics platform and services"
                    />
                    <IntegrationCard
                        user={user}
                        name="Salesforce"
                        logo={SALESFORCE_LOGO}
                        description="CRM platform for Enterprise businesses"
                    />
                </div>
                <div className="integrations-container-row">
                    <IntegrationCard
                        user={user}
                        name="SurveyMonkey"
                        logo={SURVEYMONKEY_LOGO}
                        description="Platform to create surveys and get answers"
                    />
                    <IntegrationCard
                        user={user}
                        name="Gong"
                        logo={GONG_LOGO}
                        description="Helping sales have better sales conversations"
                    />
                    <IntegrationCard
                        user={user}
                        name="Intercom"
                        logo={INTERCOM_LOGO}
                        description="Engagement OS, between customer and business"
                    />
                    <IntegrationCard
                        user={user}
                        name="G2"
                        logo={G2_LOGO}
                        description="Tech reviews & marketplace for business software"
                    />
                </div>
                <div className="integrations-container-row">
                    <IntegrationCard
                        user={user}
                        name="Email"
                        logo={EMAIL_LOGO}
                        description="Connect to your favorate email platform"
                    />
                    <IntegrationCard
                        user={user}
                        name="CSV"
                        logo={CSV_LOGO}
                        description="Upload your own data using CSV files"
                    />
                    <BlankCard />
                    <BlankCard />
                </div>
            </div>
        </div>
    );
};

export default Integrations;

import { makeAutoObservable } from "mobx";

class User {
    constructor() {
        makeAutoObservable(this);
    }

    // session
    profileName = "";
    subreddits = [];
    twitters = [];
    appStores = [];
    generationComplete = false;
    profile = "";

    // data
    selectedTweet = {};
    selectedRedditPost = {};
    selectedAppStorePost = {};
    feed = [];

    //drawers
    feedDrawer = false;
    twitterFeedDrawer = false;
    redditFeedDrawer = false;
    appStoreFeedDrawer = false;
    feedDrawerWidth = 0;
    twitterFeedDrawerWidth = 0;
    redditFeedDrawerWidth = 0;
    appStoreFeedDrawerWidth = 0;

    // charts
    tagsLabels = [];
    tagsData = [];
    subredditTagsLabels = [];
    subredditTagsData = [];
    twitterTagsLabels = [];
    twitterTagsData = [];
    appStoreTagsLabels = [];
    appStoreTagsData = [];

    // snackbars
    errorSnackbar = false;

    // filter
    redditFilter = true;
    twitterFilter = true;
    appStoreFilter = true;

    async setProfile(profile) {
        this.profile = profile;
    }

    async setRedditFilter(redditFilter) {
        this.redditFilter = redditFilter;
    }

    async setTwitterFilter(twitterFilter) {
        this.twitterFilter = twitterFilter;
    }

    async setAppStoreFilter(appStoreFilter) {
        this.appStoreFilter = appStoreFilter;
    }

    async setAppStoreTagsData(appStoreTagsData) {
        this.appStoreTagsData = appStoreTagsData;
    }

    async setAppStoreTagsLabels(appStoreTagsLabels) {
        this.appStoreTagsLabels = appStoreTagsLabels;
    }

    async setTwitterTagsLabels(twitterTagsLabels) {
        this.twitterTagsLabels = twitterTagsLabels;
    }

    async setTwitterTagsData(twitterTagsData) {
        this.twitterTagsData = twitterTagsData;
    }

    async setSubredditTagsLabels(subredditTagsLabels) {
        this.subredditTagsLabels = subredditTagsLabels;
    }

    async setSubredditTagsData(subredditTagsData) {
        this.subredditTagsData = subredditTagsData;
    }

    async setTagsLabels(tagsLabels) {
        this.tagsLabels = tagsLabels;
    }

    async setTagsData(tagsData) {
        this.tagsData = tagsData;
    }

    async setAppStoreFeedDrawerWidth(appStoreFeedDrawerWidth) {
        this.appStoreFeedDrawerWidth = appStoreFeedDrawerWidth;
    }

    async setAppStoreFeedDrawer(appStoreFeedDrawer) {
        this.appStoreFeedDrawer = appStoreFeedDrawer;
    }

    async setSelectedAppStorePost(selectedAppStorePost) {
        this.selectedAppStorePost = selectedAppStorePost;
    }

    async setTwitterFeedDrawerWidth(twitterFeedDrawerWidth) {
        this.twitterFeedDrawerWidth = twitterFeedDrawerWidth;
    }

    async setRedditFeedDrawerWidth(redditFeedDrawerWidth) {
        this.redditFeedDrawerWidth = redditFeedDrawerWidth;
    }

    async setSelectedRedditPost(selectedRedditPost) {
        this.selectedRedditPost = selectedRedditPost;
    }

    async setRedditFeedDrawer(redditFeedDrawer) {
        this.redditFeedDrawer = redditFeedDrawer;
    }

    async setTwitterFeedDrawer(twitterFeedDrawer) {
        this.twitterFeedDrawer = twitterFeedDrawer;
    }

    async setFeed(feed) {
        this.feed = feed;
    }

    async setGenerationComplete(generationComplete) {
        this.generationComplete = generationComplete;
    }

    async setErrorSnackbar(errorSnackbar) {
        this.errorSnackbar = errorSnackbar;
    }

    async setSelectedTweet(selectedTweet) {
        this.selectedTweet = selectedTweet;
    }

    async setFeedDrawerWidth(feedDrawerWidth) {
        this.feedDrawerWidth = feedDrawerWidth;
    }

    async setFeedDrawer(feedDrawer) {
        this.feedDrawer = feedDrawer;
    }

    async setProfileName(profileName) {
        this.profileName = profileName;
    }

    async setSubreddits(subreddits) {
        this.subreddits = subreddits;
    }

    async setTwitters(twitters) {
        this.twitters = twitters;
    }

    async setAppStores(appStores) {
        this.appStores = appStores;
    }
}

export default User;

// export
import { observer } from "mobx-react-lite";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { motion } from "framer-motion";
import { v4 as uuidv4 } from "uuid";
// import
import "../css/ideaForm.scss";
import Integrations from "./Integrations";
import SetProfileCustom from "./SetProfileCustom";
import { primary } from "../css/muiThemes";
import { CreateProfile } from "../api/core";

async function handleFormSubmission(user, navigate) {
    await user.setProfileName(uuidv4());
    await user.setGenerationComplete(false);
    let results = await CreateProfile(user);
    console.log(results);
    await user.setProfile(results.profile_name);
    sessionStorage.setItem("profile", results.profile_name);
    navigate("/feed");
}

const ObserveIdeaForm = observer(({ user, navigate, setCookie }) => {
    return (
        <div className="main-content-container" id="main-content-container">
            <div className="mc-header-container">
                <span className="mc-header">Integrations</span>
            </div>
            <div className="if-feedback-container">
                <motion.div
                    className="if-input-container"
                    whileHover={{ scale: 1.01 }}
                    whileTap={{ scale: 1.02 }}
                >
                    <span className="input-label">
                        👽 Subreddit slugs. This is the ID of a subreddit. Use a
                        comma to seperate many inputs.
                    </span>
                    <TextareaAutosize
                        value={user.subreddits}
                        minRows={2}
                        onChange={(event) => {
                            user.setSubreddits(event.target.value);
                        }}
                        className="if-textarea"
                        placeholder="lyftdrivers"
                    />
                </motion.div>
                <motion.div
                    className="if-input-container"
                    whileHover={{ scale: 1.01 }}
                    whileTap={{ scale: 1.02 }}
                >
                    <span className="input-label">
                        🐦 Twitter handles. Please include @ with each handle.
                        Use a comma to seperate many inputs.
                    </span>
                    <TextareaAutosize
                        value={user.twitters}
                        minRows={2}
                        onChange={(event) => {
                            user.setTwitters(event.target.value);
                        }}
                        className="if-textarea"
                        placeholder="@lyft"
                    />
                </motion.div>
                <motion.div
                    className="if-input-container"
                    whileHover={{ scale: 1.01 }}
                    whileTap={{ scale: 1.02 }}
                >
                    <span className="input-label">
                        🍎 Apple App store URLs. See example below for required
                        format. Use a comma to seperate many inputs.
                    </span>
                    <TextareaAutosize
                        value={user.appStores}
                        minRows={2}
                        onChange={(event) => {
                            user.setAppStores(event.target.value);
                        }}
                        className="if-textarea"
                        placeholder="https://apps.apple.com/us/app/lyft/id529379082"
                    />
                </motion.div>
                <motion.div
                    id="generate-website-button"
                    className="if-button-container"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.8 }}
                >
                    <Button
                        id="generate-website-button"
                        variant="contained"
                        theme={primary}
                        style={{ borderRadius: "30px" }}
                        onClick={() => {
                            handleFormSubmission(user, navigate);
                        }}
                    >
                        <span
                            className="white-text"
                            id="generate-website-button"
                        >
                            Create Profile
                        </span>
                    </Button>
                </motion.div>
            </div>
            <Integrations user={user} />
            <SetProfileCustom user={user} />
        </div>
    );
});

const IdeaForm = ({ user, navigate }) => {
    return <ObserveIdeaForm user={user} navigate={navigate} />;
};

export default IdeaForm;

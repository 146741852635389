// external
import Button from "@mui/material/Button";
// internal
import { primary } from "../css/muiThemes";

const IntegrationCard = ({ user, logo, name, description }) => {
    return (
        <div className="integration-card">
            <div className="integration-card-item">
                <div className="integration-card-item-logo">
                    <img className="integration-logo" src={logo} alt={name} />
                </div>
                <div className="integration-card-item-header">
                    <span>{name}</span>
                </div>
                <div className="integration-card-item-description">
                    <span>{description}</span>
                </div>
                <div className="integration-card-item-buttton">
                    <div
                        className="if-button-container"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.8 }}
                    >
                        <Button
                            variant="contained"
                            disabled={true}
                            theme={primary}
                            style={{ borderRadius: "30px" }}
                            onClick={() => {}}
                        >
                            <span className="white-text">Coming soon</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntegrationCard;

// export
import { observer } from "mobx-react-lite";
import { HandleFeedCardClick } from "./Helper";
import TwitterIcon from "@mui/icons-material/Twitter";
import Chip from "@mui/material/Chip";
import { v4 as uuidv4 } from "uuid";
import { motion } from "framer-motion";
// internal
import "../css/feedCard.scss";

async function handleCardClick(user, tweet) {
  await user.setSelectedTweet(tweet);
  HandleFeedCardClick(user, "twitter");
}

async function handleTwitterHandleClick(tweet) {
  window.open(`https://twitter.com/${tweet.author_handle}/`, "_blank");
}

function formatDate(date) {
  let monthArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let theDate = new Date(date);
  let day = theDate.getDate();
  let month = theDate.getMonth() + 1;
  let year = theDate.getFullYear();
  return `${monthArray[month - 1]} ${day} ${year}`;
}

const ObserveTwitterFeedCard = observer(({ user, tweet }) => {
  return (
    <motion.div
      className="mc-feedback-card"
      onClick={() => {
        handleCardClick(user, tweet);
      }}
      key={uuidv4()}
      whileHover={{ scale: 1.03 }}
      whileTap={{ scale: 0.8 }}
    >
      <div className="mc-card-user-container">
        <div className="mc-card-user-left">
          <div className="mc-card-user-icon">
            <TwitterIcon sx={{ fontSize: "14px" }} />
          </div>
          <span
            className="mc-card-user-username"
            onClick={() => {
              handleTwitterHandleClick(tweet);
            }}
          >
            {tweet.author_handle}
          </span>
        </div>
        <div className="mc-card-user-right">
          <span className="mc-card-user-date">{formatDate(tweet.date)}</span>
        </div>
      </div>
      <div className="mc-card-message-container">
        <span className="mc-card-message-text">{tweet.text}</span>
      </div>
      <div className="mc-card-topics-container">
        {tweet.tags.map((tag) => (
          <Chip
            className="mc-card-topics-chip"
            label={tag}
            variant="outlined"
            key={uuidv4()}
          />
        ))}
      </div>
    </motion.div>
  );
});

const TwitterFeedCard = ({ user, item }) => {
  return <ObserveTwitterFeedCard user={user} tweet={item} />;
};

export default TwitterFeedCard;

// export
import { observer } from "mobx-react-lite";
import { motion } from "framer-motion";
import Drawer from "@mui/material/Drawer";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import ReviewsIcon from "@mui/icons-material/Reviews";
import TwitterIcon from "@mui/icons-material/Twitter";
import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Chip from "@mui/material/Chip";
import { v4 as uuidv4 } from "uuid";
// internal
import "../css/feedDrawer.scss";
import { HandleFeedCardClose } from "./Helper";

const ObserveTwitterFeedDrawer = observer(({ user }) => {
  return (
    <Drawer
      sx={{
        width: user.twitterFeedDrawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: user.twitterFeedDrawerWidth,
        },
        backgroundColor: "#f3f5f7",
      }}
      variant="persistent"
      anchor="right"
      open={user.twitterFeedDrawer}
    >
      <div className="fd-container">
        <div className="fd-topbar-container">
          <motion.div
            className="menu-container"
            onClick={() => {
              HandleFeedCardClose(user, "twitter");
            }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.8 }}
          >
            <div className="icon-container">
              <NavigateBeforeRoundedIcon sx={{ fontSize: "40px" }} />
            </div>
          </motion.div>
        </div>

        <div className="fd-content-container">
          {user.selectedTweet ? (
            <div className="fd-content-card">
              <div className="fd-content-card-item">
                <div className="fd-content-card-icon">
                  <ChatBubbleOutlineRoundedIcon sx={{ fontSize: "22px" }} />
                </div>
                <span className="fd-content-card-text">
                  {user.selectedTweet.text}
                </span>
              </div>
              {user.selectedTweet.tags ? (
                <div className="fd-content-card-item">
                  <div className="fd-content-card-icon">
                    <ReviewsIcon sx={{ fontSize: "22px" }} />
                  </div>
                  <div className="fd-content-card-tags">
                    {user.selectedTweet.tags.map((tag) => (
                      <Chip
                        className="mc-card-topics-chip"
                        label={tag}
                        variant="outlined"
                        key={uuidv4()}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="fd-content-card-item">
                <div className="fd-content-card-icon">
                  <TwitterIcon sx={{ fontSize: "22px" }} />
                </div>
                <div className="fd-content-card-twitter-3">
                  <div className="fd-content-card-twitter-3-item">
                    <span className="fd-content-card-text">
                      {user.selectedTweet.author_handle}
                    </span>
                  </div>
                  <div className="fd-content-card-twitter-3-item">
                    <span className="fd-content-card-label">Followers</span>
                    <span className="fd-content-card-text">
                      {user.selectedTweet.num_followers}
                    </span>
                  </div>
                  <div className="fd-content-card-twitter-3-item">
                    <span className="fd-content-card-label">Following</span>
                    <span className="fd-content-card-text">
                      {user.selectedTweet.num_following}
                    </span>
                  </div>
                </div>
              </div>
              <div className="fd-content-card-item">
                <div className="fd-content-card-icon">
                  <EventIcon sx={{ fontSize: "22px" }} />
                </div>
                <span className="fd-content-card-text">
                  {new Date(user.selectedTweet.date).toLocaleString()}
                </span>
              </div>
              <div className="fd-content-card-item">
                <div className="fd-content-card-icon">
                  <LocationOnIcon sx={{ fontSize: "22px" }} />
                </div>
                <span className="fd-content-card-text">
                  {user.selectedTweet.source}
                </span>
              </div>
              <div
                className="fd-content-card-item"
                onClick={() => {
                  window.open(
                    `https://twitter.com/${user.selectedTweet.author_handle}/status/${user.selectedTweet.tweet_unique_id}`,
                    "_blank"
                  );
                }}
              >
                <span className="fd-content-card-link">Link to source</span>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Drawer>
  );
});

const TwitterFeedDrawer = ({ user }) => {
  return <ObserveTwitterFeedDrawer user={user} />;
};

export default TwitterFeedDrawer;

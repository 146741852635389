// external
import { observer } from "mobx-react-lite";
import Snackbar from "@mui/material/Snackbar";

export const ShowErrorSnackbar = observer(({ user }) => (
  <Snackbar
    open={user.errorSnackbar}
    autoHideDuration={4000}
    onClose={() => {
      user.setErrorSnackbar(false);
    }}
    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
  >
    <div className="snackbar-container">
      <span className="snackbar-text">😵 Something went wrong 😵</span>
    </div>
  </Snackbar>
));

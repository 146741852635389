// external
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// internal
import MVPHome from "./mvp/MVPHome";
import Feed from "./mvp/Feed";
import Insights from "./mvp/Insights";
// context
import User from "./context/User";
// instaniate context object
const user = new User();

const routes = (
  <Router>
    <Routes>
      <Route path="/" element={<MVPHome user={user} />} />
      <Route path="/feed" element={<Feed user={user} />} />
      <Route path="/insights" element={<Insights user={user} />} />
    </Routes>
  </Router>
);

export default routes;

const FakeFilter = ({}) => {
    return (
        <div className="i-filter-card">
            <div className="i-filter-card-row">
                <div className="i-filter-group">
                    <div className="i-filter-input-selector filter-left">
                        <span>User</span>
                    </div>
                    <div className="i-filter-input-selector filter-middle">
                        <span className="filter-text-grey">is</span>
                    </div>
                    <div className="i-filter-input-selector filter-middle">
                        <span>VIP</span>
                    </div>
                    <div className="i-filter-input-selector filter-right">
                        <span className="filter-text-grey">X</span>
                    </div>
                </div>
                <div className="i-filter-group">
                    <div className="i-filter-input-selector filter-left">
                        <span>City</span>
                    </div>
                    <div className="i-filter-input-selector filter-middle">
                        <span className="filter-text-grey">contains</span>
                    </div>
                    <div className="i-filter-input-selector filter-middle">
                        <span>New York, San Francisco</span>
                    </div>
                    <div className="i-filter-input-selector filter-right">
                        <span className="filter-text-grey">X</span>
                    </div>
                </div>
                <div className="i-filter-group">
                    <div className="i-filter-input-selector filter-left">
                        <span>Sign-up date</span>
                    </div>
                    <div className="i-filter-input-selector filter-middle">
                        <span className="filter-text-grey">after</span>
                    </div>
                    <div className="i-filter-input-selector filter-middle">
                        <span>Feb 7, 2022</span>
                    </div>
                    <div className="i-filter-input-selector filter-right">
                        <span className="filter-text-grey">X</span>
                    </div>
                </div>
                <div className="i-filter-group">
                    <div className="i-filter-input-selector filter-add">
                        <span className="filter-text-grey">+ Add filter</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FakeFilter;
